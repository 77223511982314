.mat-toolbar {
    background-color: #e65526 !important;
    height: 35px !important;
    // padding-left: 40px !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.content {
    .mat-mdc-tab-list {
        .mdc-tab {
            padding: 10px !important;
            height: 40px !important;
            border: 1px solid black;

            &.mdc-tab--active {
                border-bottom: 0px;
                border-top: 3px solid #e65526;

                .mdc-tab__content {
                    .mdc-tab__text-label {
                        color: #e65526;
                    }
                }
            }

            .mdc-tab__content {
                height: 20px;

                .mdc-tab__text-label {
                    color: black;
                }
            }

            .mdc-tab-indicator {
                display: none;
            }
        }
    }


    .mat-mdc-tab-body-content {
        padding: 10px;
        max-height: calc(100vh - 252px);
    }
}


// data-table
.cpq-data-table {

    .mdc-data-table__table {
        border: 1px solid #eee !important;
        box-shadow: none;

        .mat-header-row {
            min-height: 36px;
        }

        .mat-mdc-header-row {
            min-height: 56px !important;
            border-bottom-color: rgba(0, 0, 0, 0.12);
            background-color: #fff;

            .mat-mdc-header-cell {
                color: #444444;
                font-size: 14px;
                padding: 0px 15px;
                background-color: #fafaf9;
                text-align: left;
                text-overflow: ellipsis;
                position: relative;
                border: none;
                font-weight: 600;
            }
        }

        .mat-mdc-row {
            height: 36px;

            .mat-mdc-cell {
                color: #444444;
                border: none;
                padding: 8px 15px;
                background-color: white;
                display: table-cell;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
            }
        }
    }

    .mat-mdc-icon-button {
        padding: 0;
        min-width: 0;
        width: 40px !important;
        height: 40px !important;
        flex-shrink: 0;
        line-height: 40px !important;
        border-radius: 50% !important;
    }

    .mdc-icon-button {
        font-size: 14px !important;
        padding: 8px !important;
    }
}


.mdc-text-field {
    align-items: center !important
}

.mat-mdc-form-field {
    text-align: center !important;
}

.mdc-floating-label {
    font-size: 14px !important;
}

.mat-mdc-input-element {
    font-size: 14px !important;
    line-height: 44px !important;
}

.mat-mdc-text-field-wrapper {
    background-color: transparent !important;
    padding: 0px 5px !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 44px;

    .mat-mdc-form-field-focus-overlay {
        background-color: transparent !important;
    }

}

.mat-form-field-appearance-outline {
    .mat-mdc-floating-label {
        top: 65% !important;
    }
}

.mat-form-field-appearance-fill {
    .mat-mdc-floating-label {
        top: 40% !important;
    }
}

.header-less-tabs mat-tab-group mat-tab-header {
    display: none;
}

.mdc-dialog__content p {
    color: #e65526;
}

.mat-mdc-form-field-error {
    font-size: 12px;
}


.mdc-tab__text-label {
    font-size: 16px;
    font-weight: bold;
}

//nav bar border color
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #e65526;
}

// nav bar text color
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #e65526;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #e65526 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: #e65526 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: #e65526 !important;
}

.mat-mdc-unelevated-button {
    background-color: #e65526 !important;
    color: #fff !important;
    padding: 0px 10px !important;
    line-height: 36px !important;
}

.mat-mdc-dialog-surface {
    padding: 24px;

    .mat-mdc-dialog-content {
        padding: 0px !important;
    }
}

//dropdown selection area for opportunity details

.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) .mdc-menu-surface.mat-mdc-autocomplete-panel {
    background-color: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-panel {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
}

.textarea-field .mat-mdc-floating-label {
    top: 18px !important;
}