.formPadding {
  padding-left: 55px;
  padding-right: 55px;
  & .DynamicForm {
    & > .tab-container {
      & > .nav-tabs {
        border-bottom: 1px solid #ffffff;
        & > .customClass {
          margin: 3px 10px 0 0;
          z-index: 0 !important;
          height: 47px;
          width: 141px;
          text-align: center;
          background-color: #EEEEEE;
          border-radius: 5px 0 0 5px;
          border-top-right-radius: 5px;
          &:first-child {
            margin-left: 32px !important;
          }
          .nav-link {
            height: 100%;
            padding: 15px 0;
            color: #353839;
            background-color: #eeeeee;
            border-top: 0;
            & > label {
              color: #353839;
              margin: 0 !important;
              line-height: 16px;
              text-align: center;
              font-weight: 400;
              height: 16px;
              font-weight: bold;
              line-height: 16px;
              text-align: center;
            }
            &.active {
              padding: 11px 0;
              color: #353839 !important;
              border-top: 4px solid #353839 !important;
              padding-bottom: 13px !important;
              background-color: #ffffff;
              border: 0;
              z-index: 1;
              box-shadow: -5px 0 5px -5px #a1a1a1, 5px 0 5px -5px #a1a1a1;
              & > label {
                color: #353839;;
                font-weight: 500;
                line-height: 16px;
                text-align: center;
                height: 16px;
              }
            }
          }
        }
      }
      & > .tab-content {
        margin-top: -3px;
        & > .customClass {
          & .tabcontent {
            padding-top: 1px;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 2px 2px 2px #dad8d8;
            min-height: 445px;
            & button {
              height: 30px;
              border-radius: 3px;
              // background-color: #23a393;
              // border: solid 1px #23a393;
              color: #ffffff;
              cursor: pointer;
              width: 80px;
            }
            & .tabBreadCrumb {
              padding-left: 15px;
              width: 14rem;
              & > .type-value {
                color: #353839;
                font-weight: 600;
              }
              & + .tabBreadCrumbSeparator {
                height: 10px;
                margin-top: 3px;
                border-color: #353839 !important;
                border-width: 2px !important;
              }
            }
            & .form-group {
              position: relative;
              margin-bottom: 1.5rem;
              & > input.custom-form-control {
                display: block;
                width: 100%;
                height: calc(2.25rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                padding: 0;
                border: none;
                border-bottom: 1px solid #dce4e8;
                &:focus,
                &[floatingLabel="true"] {
                  border-color: none;
                  box-shadow: none;
                  & + .form-control-placeholder {
                    transform: translate3d(0, -100%, 0);
                    opacity: 1;
                  }
                }
              }
              & > input.form-control[readonly] {
                background-color: #ffffff;
              }
              & > input.form-control,
              select.form-control {
                padding: 0;
                border: none;
                border-bottom: 1px solid #dce4e8;
                -webkit-appearance: none;
                -moz-appearance: none;
                display: block;
                width: 100%;
                float: right;
                color: #333;
                background-color: #ffffff;
                background-image: none;
                -ms-word-break: normal;
                word-break: normal;
                &:focus,
                &:valid,
                &[readonly] {
                  border-color: none;
                  box-shadow: none;
                  & + .form-control-placeholder {
                    transform: translate3d(0, -100%, 0);
                    opacity: 1;
                  }
                }
              }
              & > .form-control-placeholder {
                position: absolute;
                top: 0;
                padding: 7px 0 0 0;
                transition: all 200ms;
                opacity: 1;
                pointer-events: none;
              }
              & > .CheckBoxcontainer {
                position: relative;
                padding-left: 21px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                & input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  &:checked {
                    & ~ .checkmarkTile {
                      &:after {
                        display: block;
                      }
                    }
                  }
                }
                & .checkmarkTile {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 18px;
                  width: 18px;
                  border-radius: 3px;
                  background-color: #fff;
                  border: 1px solid #dadde0;
                  &:after {
                    left: 6px;
                    top: 2px;
                    width: 5px;
                    height: 10px;
                    border: solid #E65526;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    content: "";
                    position: absolute;
                    display: none;
                  }
                }
              }
              & > .ToggleActive {
                border-radius: 16px;
                box-shadow: 0 2px 4px 0 rgba(35, 163, 147, 0.29);
                border: 2px solid #353839;
                background-color: #ffffff;
                font-size: 12px !important;
                text-transform: uppercase;
                border-radius: 100px !important;
                & > label {
                  padding: 0.2rem 0.25rem;
                  margin-bottom: 0px;
                  color: #353839;
                  width: 81px;
                  border-radius: 100px !important;
                  line-height: 22px;
                  text-transform: uppercase;
                  &.active {
                    color: white !important;
                    background-color: #353839;
                    border-radius: 100px !important;
                    padding: 0.2rem 0.25rem;
                    width: 81px;
                    border: 1px solid #353839;
                  }
                  &.inactive {
                    color: #353839;
                    font-weight: 500;
                  }
                  &:focus {
                    box-shadow: none !important;
                  }
                }
              }
            }
          }
          & .TabMovebutton {
            color: #353839;
            box-shadow: 0 2px 2px 0 #c3c3c3, 0 1px 3px 0 #a2a2a2;
            width: 100px;
            height: 30px;
            border-radius: 2px;
            background-color: #ffffff;
            border: solid 1px #e4e3e3;
            font-weight: 500;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            margin: 10px 6px;
            box-shadow: 0 2px 2px 0 #c3c3c3, 0 1px 3px 0 #a2a2a2;
            & > span {
              margin-left: 10px;
              margin-right: 5px;
            }
            &.btn-finish {
              margin-right: 0;
              background-color: #E65526;
              color: #ffffff;
              box-shadow: 0px 2px 2px 0 rgba(98, 107, 105, 0.44);
              border: 2px solid #E65526;
            }
            &.btn-finish:hover {
              background-color: #E65526 !important;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.mb-25 {
  margin-bottom:25px;
}

.customDropdownStyle:after {
  content: ">";
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  top: 7px;
  position: absolute;
  pointer-events: none;
}

.error {
  color: #f91a43;
  font-weight: 500;
}
.star {
  color: #f91a43;
}

.refBtnStyle {
  height: 30px;
  border-radius: 3px;
  background-color: #23a393;
  border: solid 1px #23a393;
  color: #ffffff;
  cursor: pointer;
}

.floating-form-headers {
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 20px;
  color: #353839;
  font-family: Roboto;
  line-height: 16px;
}

@media only screen and (min-width: 992px) {
  .DynamicForm {
    & li.customClass {
      height: 40px;
      width: 141px;
    }

    & .ToggleActive {
      width: 165px;
      height: 33px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .DynamicForm {
    & li.customClass {
      height: 30px;
      width: 80px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .DynamicForm {
    & li.customClass {
      height: 35px;
      width: 100px;
    }
    & .ToggleActive {
      width: 125px;
      height: 30px;
    }
    & .TabMovebutton {
      width: 70px;
      height: 40px;
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .DynamicForm {
    & li.customClass {
      width: 10rem !important;
      margin-right: 5px !important;
    }
  }
}

@media screen and (min-width: 991px) and (max-width: 1024px) {
  .TabMovebutton {
    width: 60px;
    height: 40px;
    font-size: 10px;
  }
}

@media screen and (min-width: 469px) and (max-width: 767px) {
  .TabMovebutton {
    width: 80px;
    height: 40px;
    font-size: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 468px) {
  .TabMovebutton {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }
}

@media screen and (max-width: 319px) {
  .TabMovebutton {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }
}
